import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import ShareableGiftSelectionPage from "../social-supermarket/pages/gift-selection/ShareableGiftSelectionPage"
import useQueryString from "../social-supermarket/hooks/useQueryString"
import PublicGiftSelector from "../social-supermarket/pages/gift-selection/PublicGiftSelector"

const Container = styled.div``

interface Props {}

const GiftSelector: FC<Props> = () => {
  const [orderKey, setOrderKey] = useQueryString("key", "")
  return <PublicGiftSelector orderKey={orderKey} />
}

export default GiftSelector
